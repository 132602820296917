.headerCustom {
  display: none;
}
.exitApp:hover {
  cursor: pointer;
}
.loggedInUser {
  display: flex;
  align-items: center;
}
.hiPerson {
  color: #858a9e;
}
.personIcon {
  background-color: #cebce8;
  width: 30px !important;
  height: 30px !important;
  border-radius: 8px;
  color: #3b0a84;
}

@media only screen and (min-width: 992px) {
  .headerCustom {
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    padding-left: 280px;
    position: fixed;
    z-index: 10;
    width: 100%;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
  }
}

.relative {
  position: relative;
}

.navBg {
  background-color: #fff;
}
