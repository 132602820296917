//
// Override global variables
//

// Theme colors
$primary: #3b0a84;
$primary-hover: darken($primary, 5%);
// $primary-light: #c9f7f5;
$primary-inverse: #ffffff;

$secondary: #f1e8fe;
$secondary-hover: darken($secondary, 5%);
$secondary-light: #8245fc;
$secondary-inverse: #3b0a84;

$info: #e8f4ff;
$info-hover: darken($info, 5%);
// $info-light: #e1e9ff;
$info-inverse: #369aff;

$success: #caf7f5;
$success-hover: darken($success, 5%);
// $success-light: #1bc5be;
$success-inverse: #1bc5be;

$warning: #ffe8bc;
$warning-hover: darken($warning, 5%);
// $warning-light: #1bc5be;
$warning-inverse: #ffa801;

$danger: #ffe2e5;
$danger-hover: darken($danger, 5%);
// $danger-light:
$danger-inverse: #f74e60;

// #1bc5b
$light: #ececec;
$light-hover: darken($light, 5%);
// $light-light:
$light-inverse: #3f4355;

$theme-primary: #3f4355;
$form-label: #3f4355;
